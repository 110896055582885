import {extendTheme} from "@chakra-ui/react"

const ChakraTheme = extendTheme({
    fonts: {
        heading: "Inter, sans-serif",
        body: "Inter, sans-serif",
    },
    colors: {
        brand: '#e84855'
    },
    components: {
        Progress: {
            baseStyle: {
                filledTrack: {
                    bg: '#00a05b'
                }
            }
        }
    }
})

export default ChakraTheme;
